import { configureStore } from '@reduxjs/toolkit'
import signupReducer from './features/auth/signupSlice'
import loginReducer from './features/auth/loginSlice'
import pricingReducer from './features/Pricing/pricingSlice'

export const store = configureStore({
   reducer: {
      signup: signupReducer,
      login: loginReducer,
      pricing: pricingReducer
   }
})