/**
 * Predefined libraries
 */
import React, { useEffect } from "react";

/**
 * Components
 */
import Navbar from "../../Components/Navbar/Navbar";
import Banner from "./components/Banner/Banner";
import ContactForm from "./components/form/Form";
import Footer from "../../Components/Footer/Footer";

import PageTitle from "../../Components/PageTitle/pagetitle";

/**
 * Routes
 */
import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

/**
 * Constants
 */
const FILE_NAME = "contactora.js";
const TITLE = "Contact Ora";

/**
 * Styles
 */

const ContactOra = () => {
	console.log(`In ${FILE_NAME}`);
	const { pathname } = useLocation();
	useEffect(() => {
		Aos.init({
			duration: 500,
			easing: "ease-in-out",
		});
		Aos.refresh();
	}, []);

	useEffect(() => {
		document.querySelector("html").style.scrollBehavior = "auto";
		window.scroll({ top: 0 });
		document.querySelector("html").style.scrollBehavior = "";
	}, [pathname]);

	return (
		<>
			<PageTitle title={TITLE} />
			<div>
				<Navbar />
				<Banner />
				<ContactForm />
				<Footer />
			</div>
		</>
	);
};

export default ContactOra;
