import React from 'react';

// import './banner.css'

import vector from './assets/HomePageVector.svg'


const Banner = () => {

    const handleClick = (event) => {
        event.preventDefault();
        const targetId = event.target.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        const offsetTop = targetElement.offsetTop;

        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth',
        });
    }

    return (
        <section id="home" className="text-gray-600 light-purple-bg body-font">
            <div className="w-9/12 mx-auto flex px-5 pt-24 md:flex-row flex-col">
                <div
                    className="lg:flex-grow lg:w-1/2 md:w-3/5 flex pt-10 pb-2 flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-easing="ease-in-out">
                    <h1 className="title-font text-4xl lg:text-5xl xl:text-6xl mb-4 font-medium text-gray-600">Your
                        Event</h1>
                    <h1 className="title-font text-4xl lg:text-5xl xl:text-6xl mb-4 font-medium text-gray-600">Our
                        Platform</h1>
                    <h1 className="title-font text-4xl lg:text-5xl xl:text-6xl mb-8 font-medium text-gray-600">Your
                        Memories</h1>
                    <p className="mb-8 leading-relaxed">Make event hosting easy breezy with Ora</p>
                    <div className="flex justify-center">
                        <a href="#aboutus" onClick={handleClick}
                            className="inline-flex text-orange border border-orange py-2 px-6 focus:outline-none hover:bg-orange hover:text-white rounded text-lg sm:text-sm">1 Minute Video</a>

                    </div>
                </div>
                <div className="hidden md:flex justify-end lg:w-1/2 md:w-2/5 w-5/6 pr-0"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-easing="ease-in-out">
                    <img className="object-cover object-center rounded h-full" alt="hero" src={vector} />
                </div>
            </div>
        </section>
    );
};

export default Banner;
