/**
 * 
 * FILE_NAME: App.js
 * Objective: Root File
 * Author: Ankit Dhawan
 * Project: Ora
 * Tech used: react(18.2.0)
 * 
 */
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

/**
 * Components
 */
import LandingPage from './Pages/LandingPage/landingpage';
import ContactOra from './Pages/ContactOra/ContactOra';

//Policies
import PrivacyPolicy from './Pages/Policies/PrivacyPolicy/privacypolicy';
import TermsOfUse from './Pages/Policies/TermsofUse/termsofuse';
import ReturnAndRefundPolicy from './Pages/Policies/ReturnAndRefundPolicy/ReturnAndRefundPolicy';
import EULA from './Pages/Policies/EULA/EULA';
import Error404 from './Components/404/Error404';
import Login from './Pages/Auth/Login/Login';
import Signup from './Pages/Auth/Signup/Signup';
import Pricing from './Pages/Pricing/Pricing';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/contactora',
    element: <ContactOra />
  },
  {
    path: "/pricing",
    element: <Pricing />
  },
  {
    path: '/privacypolicy',
    element: <PrivacyPolicy />
  },
  {
    path: '/termsofuse',
    element: <TermsOfUse />
  },
  {
    path: '/returnandrefundpolicy',
    element: <ReturnAndRefundPolicy />
  },
  {
    path: '/eulapolicy',
    element: <EULA />
  },
  {
    path: '*',
    element: <Error404 />
  },
])

const FILE_NAME = 'App.js'
const App = () => {
  console.log(`In ${FILE_NAME}`)

  return (
    <RouterProvider router={router} />
  )
};

export default App;