import axios from "axios";

export async function subscribe(data) {
  try {
    const response = await axios.post('https://ora-server.in/api/v1/services/newsletter/subscribe', data);
    console.log("subscribe newsletter response", response);
    return response;
  } catch (error) {
    console.log('axios subscribe error: ', error)
    return error;
  }
}