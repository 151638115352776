import React from "react";

const Content = () => {
        return (
                <div>
                  <style dangerouslySetInnerHTML={{__html: "\n        [data-custom-class='body'],\n        [data-custom-class='body'] * {\n                background: transparent !important;\n        }\n\n        [data-custom-class='title'],\n        [data-custom-class='title'] * {\n                font-family: Arial !important;\n                font-size: 26px !important;\n                color: #000000 !important;\n        }\n\n        [data-custom-class='subtitle'],\n        [data-custom-class='subtitle'] * {\n                font-family: Arial !important;\n                color: #595959 !important;\n                font-size: 14px !important;\n        }\n\n        [data-custom-class='heading_1'],\n        [data-custom-class='heading_1'] * {\n                font-family: Arial !important;\n                font-size: 19px !important;\n                color: #000000 !important;\n        }\n\n        [data-custom-class='heading_2'],\n        [data-custom-class='heading_2'] * {\n                font-family: Arial !important;\n                font-size: 17px !important;\n                color: #000000 !important;\n        }\n\n        [data-custom-class='body_text'],\n        [data-custom-class='body_text'] * {\n                color: #595959 !important;\n                font-size: 14px !important;\n                font-family: Arial !important;\n        }\n\n        [data-custom-class='link'],\n        [data-custom-class='link'] * {\n                color: #3030F1 !important;\n                font-size: 14px !important;\n                font-family: Arial !important;\n                word-break: break-word !important;\n        }\n" }} />
                  <div data-custom-class="body">
                    <div>
                      <div align="center" className="MsoNormal" style={{textAlign: 'center', lineHeight: '115%'}}><a name="_2cipo4yr3w5d" />
                        <div align="center" className="MsoNormal" style={{textAlign: 'left', lineHeight: '150%'}}><strong><span style={{fontSize: '26px'}}><span data-custom-class="title">RETURN
                                POLICY</span></span></strong></div>
                        <div align="center" className="MsoNormal" style={{textAlign: 'left', lineHeight: '150%'}}><br /></div>
                        <div align="center" className="MsoNormal" style={{textAlign: 'left', lineHeight: '150%'}}><span style={{fontSize: '15px'}}><span style={{color: 'rgb(89, 89, 89)'}}><strong><span data-custom-class="subtitle">Last updated <bdt className="question">May 02, 2023</bdt>
                                </span></strong></span></span></div>
                        <div align="center" className="MsoNormal" style={{textAlign: 'left', lineHeight: '150%'}}><br /></div>
                        <div align="center" className="MsoNormal" style={{textAlign: 'left', lineHeight: '150%'}}><span style={{fontSize: '15px'}}><br /><a name="_2cipo4yr3w5d" /></span></div>
                      </div>
                      <div className="MsoNormal" data-custom-class="body_text" style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', lineHeight: '115%', fontFamily: 'Arial', color: 'rgb(89, 89, 89)'}}>
                          <bdt className="block-component" />
                        </span></div>
                      <div data-custom-class="heading_1"><strong><span style={{fontSize: '19px'}}>REFUNDS</span></strong></div>
                      <div style={{lineHeight: '1.5'}}><br /></div>
                      <div data-custom-class="body_text" style={{lineHeight: '1.5'}}><span style={{fontSize: '15px'}}>All sales
                          are final and no refund will be issued.<bdt className="block-component" /></span></div>
                      <div style={{lineHeight: '1.5'}}><br /></div>
                      <div data-custom-class="heading_1" style={{lineHeight: '1.5'}}><span style={{fontSize: '19px', color: 'rgb(0, 0, 0)'}}><strong>QUESTIONS</strong></span></div>
                      <div style={{lineHeight: '1.5'}}><br /></div>
                      <div data-custom-class="body_text" style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>If you have any questions concerning
                          our return policy, please contact us at:</span></div>
                      <div data-custom-class="body_text" style={{lineHeight: '1.1'}}><br /></div>
                      <div data-custom-class="body_text" style={{lineHeight: '1.5'}}><span style={{fontSize: '15px'}}>
                          <bdt className="block-component" />
                        </span></div>
                      <div data-custom-class="body_text" style={{lineHeight: '1.5'}}><span style={{fontSize: '15px'}}>
                          <bdt className="question"><span style={{color: 'rgb(89, 89, 89)'}}>9773967814</span></bdt><span style={{color: 'rgb(89, 89, 89)'}}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span></div>
                      <div data-custom-class="body_text" style={{lineHeight: '1.5'}}><span style={{fontSize: '15px', color: 'rgb(89, 89, 89)'}}>
                          <bdt className="question">help@weareora.in</bdt>
                        </span></div>
                      <style dangerouslySetInnerHTML={{__html: "\n                        ul {\n                                list-style-type: square;\n                        }\n\n                        ul>li>ul {\n                                list-style-type: circle;\n                        }\n\n                        ul>li>ul>li>ul {\n                                list-style-type: square;\n                        }\n\n                        ol li {\n                                font-family: Arial;\n                        }\n                " }} />
                    </div>
                  </div></div>
              );
}

export default Content;
