import React from 'react';
import OraLogo from './assets/oralogoblue.svg'
import AppStore from './assets/appstore.svg';
import PlayStore from './assets/playstore.svg';
import WhatsApp from './assets/whatsapp.svg';
import Email from './assets/mail.svg';
import Instagram from './assets/insta.svg';
import LinkedIn from './assets/linkedin.svg';
import Youtube from './assets/youtube.svg';

import { NavHashLink } from 'react-router-hash-link';
import './footer.css'

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src={OraLogo} alt="logo" className="me-2" />
            <p>Ora is the ultimate event hosting platform that allows you to create and host any event with ease and offers a wide range of theme customizations.</p>
          </div>
          <div className="col-md-3">
            <h6 className="mb-3">Useful Links</h6>
            <ul className="list-unstyled">

              <li>
                <NavHashLink smooth to="/#home">
                  Home
                </NavHashLink>
              </li>
              <li>
                <NavHashLink smooth to="/#aboutus" >
                  About Us
                </NavHashLink>
              </li>

              <li>
                <NavHashLink smooth to="/#features" >
                  Features
                </NavHashLink>
              </li>
              <li><NavHashLink to="/contactora">Contact Us</NavHashLink></li>

              {/* <li><a href="/pricing">Pricing</a></li> */}
            </ul>
          </div>
          <div className="col-md-3">
            <h6 className="mb-3">Policies</h6>
            <ul className="list-unstyled">
              <li><a href="/privacypolicy">Privacy Policy</a></li>
              <li><a href="/termsofuse">Terms of Use</a></li>
              <li><a href="/returnandrefundpolicy">Return and Refund</a></li>
              <li><a href="/eulapolicy">EULA Policy</a></li>
            </ul>
          </div>
          <div className="col-md-3">
            <div className="d-flex align-items-center mb-3">
              <h6 className="mb-0">Keep In Touch</h6>
            </div>
            <div className="d-flex align-items-center mb-3">
              <p className="mb-0">Ansal Sushant Estate, Sector 52, Gurugram, Haryana, India</p>
            </div>
            <div className="d-flex align-items-center mb-3">
              <p className="mb-0">+91 9773967814, +91 9958618406</p>
            </div>
            <div className="d-flex align-items-center mb-3">
              <p className="mb-0">https://weareora.in/</p>
            </div>
            <ul className="list-unstyled social-media-icons">
              <li><a href="https://www.linkedin.com/in/ora-team-654b4a26b/" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="LinkedIn" /></a></li>
              <li><a href="https://www.instagram.com/weareora.in/" target="_blank" rel="noreferrer"><img src={Instagram} alt="Instagram" /></a></li>
              <li><a href="https://www.youtube.com/@weareora" target="_blank" rel="noreferrer"><img src={Youtube} alt="YouTube" /></a></li>
              <li><a href="https://wa.me/919958618406?text=hi%2C%20I%20have%20a%20query%20regarding%20Ora" target="_blank" rel="noreferrer"><img src={WhatsApp} alt="Whatsapp" /></a></li>
              <li><a href="mailto:help@weareora.in?bcc=weareora.official@gmail.com" target="_blank" rel="noreferrer"><img src={Email} alt="Email" /></a></li>
            </ul>
          </div>
        </div>
        <div className="text-gray-600 body-font">
          <hr className="w-9/12 mx-auto" />
          <div className="w-9/12 mx-auto px-5 pt-5 pb-20 flex items-center md:flex-row flex-col">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-sm text-gray-500 font-medium title-font mb-1">All rights reserved © 2023</h2>
            </div>
            <div className="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
              <a href="/"><img src={AppStore} alt="App Store" className="me-3" /></a>
              <a href="/"><img src={PlayStore} alt="Play Store" /></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
