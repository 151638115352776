import React, { useState, useEffect } from "react";
import "./style.css";
import OraLogo from "./assets/oralogopurple.svg";
import { NavHashLink } from "react-router-hash-link";

function Navbar() {
	const [navbarColor, setNavbarColor] = useState("transparent");
	const [showNav, setShowNav] = useState(false);
	const [userLogged, setUserLogged] = useState();

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 70) {
				setNavbarColor("white");
			} else {
				setNavbarColor("transparent");
			}
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleShowNav = () => {
		setShowNav(!showNav);
		if (showNav) {
			setNavbarColor("transparent");
		} else {
			setNavbarColor("white");
		}
	};

	const handleNavClick = () => {
		setShowNav(false);
	};

	useEffect(() => {
		let localData = JSON.parse(localStorage.getItem("user"));
		let token = JSON.parse(localStorage.getItem("token"));

		if (token && localData) {
			setUserLogged(true);
		} else {
			setUserLogged(false);
		}
	}, []);

	return (
		<header className='light-purple-bg navbar-main'>
			<nav
				className='flex items-center justify-around px-6 py-3 navbar-wrapper'
				style={{
					backgroundColor: navbarColor,
					position: "fixed",
					"z-index": "9999",
					width: "100%",
				}}
			>
				<div className='nav-logo'>
					<NavHashLink to='/' className=''>
						<span className='sr-only'>Ora Company</span>
						<img className='h-8' src={OraLogo} alt='logo' />
					</NavHashLink>
				</div>

				<div
					className={`nav-links flex gap-x-12 ${
						showNav ? "nav-active" : ""
					}`}
					onClick={handleNavClick}
				>
					<NavHashLink
						smooth
						to='/#home'
						className='text-sm font-semibold leading-6 text-gray-900 hover:text-purple-500'
					>
						Home
					</NavHashLink>
					<NavHashLink
						smooth
						to='/#aboutus'
						className='text-sm font-semibold leading-6 text-gray-900 hover:text-purple-500'
					>
						About Us
					</NavHashLink>
					<NavHashLink
						smooth
						to='/#features'
						className='text-sm font-semibold leading-6 text-gray-900 hover:text-purple-500'
					>
						Features
					</NavHashLink>
					<NavHashLink
						smooth
						to='/pricing'
						className='text-sm font-semibold leading-6 text-gray-900 hover:text-purple-500'
					>
						Pricing
					</NavHashLink>
					{/* <NavHashLink
						smooth
						to='/contactora'
						className='text-sm font-semibold leading-6 text-gray-900 hover:text-purple-500'
					>
						Contact Us
					</NavHashLink> */}
				</div>

				{showNav ? (
					<div className='hamburger-menu' onClick={handleShowNav}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='30'
							height='30'
							viewBox='0 0 24 24'
						>
							<path
								fill='#8b2be2'
								d='m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 13.4Z'
							/>
						</svg>
					</div>
				) : (
					<div className='hamburger-menu' onClick={handleShowNav}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='30'
							height='30'
							viewBox='0 0 48 48'
						>
							<path
								fill='none'
								stroke='#8b2be2'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='4'
								d='M7.95 11.95h32m-32 12h32m-32 12h32'
							/>
						</svg>
					</div>
				)}

				<div className={`nav-btn ${showNav ? "nav-active" : ""}`}>
						<NavHashLink to='/contactora'>
							<button className='goto-dash-btn bg-white  hover:bg-purple-500 hover:text-white rounded text-black text-xs w-32 -ml-5 h-9'>
								Contact Us
							</button>
						</NavHashLink>
				</div>
			</nav>
		</header>
	);
}
export default Navbar;
