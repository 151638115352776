import { createSlice } from "@reduxjs/toolkit";
import { pricingData } from "./pricingActions";

const initialState = {
   loading: null,
   data: [],
   success: null,
   status: null,
   error: null,
   message: null
}

const pricingSlice = createSlice({
   name: "pricing",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder.addCase(pricingData.pending, (state,) => {
         state.loading = true
      })
      builder.addCase(pricingData.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.data = payload?.response?.data
         state.status = payload?.response?.status
         state.success = true
      })
      builder.addCase(pricingData.rejected, (state, { payload }) => {
         state.loading = false
         state.error = true
         state.message = payload.response.console.error;
      })
   }
})

export default pricingSlice.reducer