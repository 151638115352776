import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import Content from "./content";

import PageTitle from '../../../Components/PageTitle/pagetitle';

import "./style.css";

/**
 * Constants
 */
const FILE_NAME = "termsofuse.js";
const TITLE = "Terms of Use"

const TermsOfUse = () => {
  console.log(`In ${FILE_NAME}`)
  return (
    <>
      <PageTitle title={TITLE} />
      <div>
        <div className="tos-nav">
          <Navbar />
        </div>
        <Content />
      </div>
    </>
  );
};

export default TermsOfUse;
