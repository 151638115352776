import React, { useState } from "react";
import "./form.css";

/**
 * ASSETS
 */
import Email from "./assets/email.svg";
import Phone from "./assets/phone.svg";
// import WhatsApp from './assets/whatsapp.svg';
import Location from "./assets/location.svg";

import Loader2 from "../../../../Components/Loader2/Loader2";
/**
 * SERVICES
 */
import { submitResponse } from "./services/axios";

const ContactForm = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const [showModal, setShowModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const onHandleSubmitClick = async (event) => {
		event.preventDefault();
		setLoading(true);
		console.log("data: ", name, phone, email, message);

		if (name === "" && phone === "" && email === "" && message === "") {
			setShowErrorModal(true);
			setLoading(false);
			return;
		}

		const data = {
			name: name,
			email: email,
			phone: phone,
			message: message,
		};

		const response = await submitResponse(data);

		if (response && response.status !== 200) {
			setShowErrorModal(true);
			setLoading(false);
			return;
		}

		setShowModal(true);
		setLoading(false);
		setName("");
		setEmail("");
		setPhone("");
		setMessage("");
	};

	return (
		<>
			{showModal ? (
				<>
					<div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
						<div className='relative w-auto my-6 mx-auto max-w-3xl'>
							{/*content*/}
							<div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
								{/*body*/}
								<div className='relative p-6 pb-0 flex-auto'>
									<p className='mt-4 text-slate-500 text-lg leading-relaxed'>
										We will contact you soon!!!
									</p>
								</div>
								{/*footer*/}
								<div className='flex items-center justify-end p-6 border-solid border-slate-200 rounded-b'>
									<button
										className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
										type='button'
										onClick={() => setShowModal(false)}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
				</>
			) : null}
			{showErrorModal ? (
				<>
					<div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
						<div className='relative w-auto my-6 mx-auto max-w-3xl'>
							{/*content*/}
							<div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
								{/*body*/}
								<div className='relative p-6 pb-0 flex-auto'>
									<p className='mt-4 text-slate-500 text-lg leading-relaxed'>
										we couldn't initiate the contact, please
										check the details.
									</p>
								</div>
								{/*footer*/}
								<div className='flex items-center justify-end p-6 border-solid border-slate-200 rounded-b'>
									<button
										className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
										type='button'
										onClick={() => setShowErrorModal(false)}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
				</>
			) : null}

			<div className='contact-form-main-container'>
				<div class='contact-form'>
					<div class='contact-info'>
						<h2>Contact Information</h2>
						<ul>
							<li>
								<img src={Location} alt='ora address' />
								<i class='fa fa-map-marker'></i>Ansal Sushant
								Estate, Sector 52, Gurugram, Haryana
							</li>
							<li>
								<img src={Phone} alt='ora mobile number' />
								<i class='fa fa-phone'></i>+91-9773967814
							</li>
							<li>
								<img src={Email} alt='ora email' />
								<i class='fa fa-envelope'></i>help@weareora.in
							</li>
						</ul>
					</div>
					{loading && (
						<div className='contactform-loader'>
							<Loader2 />
						</div>
					)}

					<div class='contact-form-container'>
						<form action='#' method='post'>
							<input
								name='name'
								placeholder='Full name'
								type='text'
								id='name'
								value={name}
								onChange={(event) =>
									setName(event.target.value)
								}
								required
							></input>
							<input
								name='email'
								placeholder='Email ID'
								type='email'
								id='email'
								value={email}
								onChange={(event) =>
									setEmail(event.target.value)
								}
								required
							></input>
							<input
								name='phone'
								placeholder='Mobile number'
								type='tel'
								id='phone'
								value={phone}
								onChange={(event) =>
									setPhone(event.target.value)
								}
								required
							></input>
							<textarea
								name='message'
								placeholder='Type your message here...'
								rows='5'
								id='message'
								value={message}
								onChange={(event) =>
									setMessage(event.target.value)
								}
								required
							></textarea>
							<button type='submit' onClick={onHandleSubmitClick}>
								Send Message
							</button>
						</form>
					</div>
				</div>
			</div>

			{/* <div className="contact-form-container">
        <div className="left-side">
          <div className="card">
            <h2>Contact Information<br/><p>Fill up the form and out Team will get back to you.</p></h2>
            
            <div className="contact-info">
              <div className="info-item">
                <img src={Location} alt="Address Icon" />
                <p>Ansal Sushant Estate, Sector 52, Gurugram, Haryana, India</p>
              </div>
              <div className="info-item">
                <img src={Phone} alt="Phone Icon" />
                <p>+91 9773967814</p>
              </div>
              <div className="info-item">
                <img src={WhatsApp} alt="WhatsApp Icon" />
                <p>+91 9958618406</p>
              </div>
              <div className="info-item">
                <img src={Email} alt="Email Icon" />
                <p>ankit.dhawan@weareora.in</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-side">
          <div className="card">
            <form>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input placeholder="E.g. John Doe" type="text" id="name" value={name} onChange={(event) => setName(event.target.value)} required />
              </div>
              <div className="email-phone-container">
                  <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input placeholder="E.g. john.doe@weareora.in" type="email" id="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
                  </div>
                  <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input placeholder="E.g. 9773967814" type="tel" id="phone" value={phone} onChange={(event) => setPhone(event.target.value)} required />
                  </div>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea placeholder="Type your message here..." rows="5" id="message" value={message} onChange={(event) => setMessage(event.target.value)} required />
              </div>
              <button type="submit" className="submit-button" onClick={ onHandleSubmitClick } >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div> */}
		</>
	);
};

export default ContactForm;
