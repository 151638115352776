import axios from "axios";

export async function submitResponse(data) {
  try {
    const response = await axios.post('https://ora-server.in/api/v1/services/contact/mail', data);
    console.log("contact ora response", response);
    return response;
  } catch (error) {
    console.log('axios contact ora error: ', error)
    return error;
  }
}