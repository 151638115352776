import React from "react";

// import './banner.css'

import vector from "./assets/ContactPageVector.svg";

const Banner = () => {
	return (
		<section className='text-gray-600 light-purple-bg body-font'>
			<div className='w-9/12 mx-auto flex px-5 pt-24 md:flex-row flex-col'>
				<div
					data-aos='fade-up'
					className='lg:flex-grow lg:w-1/2 md:w-3/5 flex pt-10 pb-2 flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center'
				>
					<h1 className='title-font text-4xl lg:text-5xl xl:text-6xl mb-4 font-medium text-gray-600'>
						Contact Us
					</h1>
					<p className='mb-8 leading-relaxed'>
						Any questions or remarks? Just right us a message
					</p>
				</div>
				<div className='hidden md:flex justify-end lg:w-1/2 md:w-2/5 w-5/6 pr-0'>
					<img
						data-aos='fade-up'
						className='object-cover object-center rounded h-full'
						alt='hero'
						src={vector}
					/>
				</div>
			</div>
		</section>
	);
};

export default Banner;
