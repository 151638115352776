import React from "react";
import "./style.css";
import Navbar from "../../../Components/Navbar/Navbar";
import Content from "./Content";

import PageTitle from "../../../Components/PageTitle/pagetitle";

const FILE_NAME = "EULA.jsx";
const TITLE = "EULA";

const EULA = () => {
	console.log(`In ${FILE_NAME}`);

	return (
		<>
			<PageTitle title={TITLE} />
			<div>
				<div className='eula-nav'>
					<Navbar />
				</div>
				<div className='eula-content'>
					<Content />
				</div>
			</div>
		</>
	);
};

export default EULA;
