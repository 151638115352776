import React from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import Content from "./content";

import PageTitle from "../../../Components/PageTitle/pagetitle";

import './style.css'

/**
 * Constants
 */
const FILE_NAME = 'privacypolicy.js';
const TITLE = 'Privacy Policy'

const PrivacyPolicy = () => {
  console.log(`In ${FILE_NAME}`)
  return (
    <>
      <PageTitle title={TITLE} />
      <div>
        <div className="pp-nav">
          <Navbar />
        </div>
        <div className='pp-content'>
          <Content />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
