

export const setVerifyLocalStorage = (data, res) => {
   localStorage.setItem('user', JSON.stringify(data));
   localStorage.setItem('token', JSON.stringify(res.data.token));
}

export const setUserDataLocalStorage = (data, res) => {
   localStorage.setItem('user', JSON.stringify(data));
   localStorage.setItem('token', JSON.stringify(res.data.token));
}