/**
 * Predefined libraries
 */
import React from 'react';

/**
 * Components
 */
import { FeaturesData } from './featuresdata';

const FILE_NAME = 'features.js';


const Features = () => {
  console.log(`In ${FILE_NAME}`)



  return (
    <>
      <section id="features" className="text-gray-600 body-font">
        <div className="w-9/12 px-5 py-24 mx-auto" data-aos="fade-up" data-aos-duration="800"
          data-aos-easing="ease-in-out" data-aos-delay="50">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="sm:text-3xl text-2xl font-bold title-font mb-4 text-heading">Say Hi To Ora’s Efficient Features</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-description">Elevate Your Event Experience with Ora's Marketing and Analytics Tools and 50+ Theme Customization Options.</p>
          </div>
          <div className="flex flex-wrap -m-4"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-out">
            {FeaturesData.map(item => (
              <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                <div className="h-full text-center">
                  <img alt="testimonial" className="w-20 h-20 mb-8 object-cover object-center inline-block" src={item.icon} />
                  <h2 className="text-gray-900 font-medium mb-5 title-font tracking-wider text-sm" style={{ fontSize: 16, fontWeight: 'bold' }} >{item.heading}</h2>
                  <p className="text-gray-500" >{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
};

export default Features;