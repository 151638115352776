/**
 * Predefined libraries
 */
import React, { useState } from 'react';

/**
 * Styles
 */
import './newsletterstyle.css'

/**
 * Assets
 */
import vector from './Assets/Frame 29.svg'

/**
 * Services
 */
import { subscribe } from './services/axios';
import Loader from '../../../../Components/Loader/Loader';

const FILE_NAME = 'newsletter.js';

const NewsLetter = () => {
    const [showModal, setShowModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [loading, setLoading] = useState(false)

    console.log(`In ${FILE_NAME}`);

    const [email, setEmail] = useState('');

    const onHandleSubmitClick = async (event) => {
        event.preventDefault();
        setLoading(true)
        if (email === '') {
            setShowErrorModal(true);
            setLoading(false);
            return;
        }

        const data = {
            email: email,
        };

        const response = await subscribe(data);
        setLoading(false)

        console.log("ye rha response", response)

        if (response && response.status !== 200) {
            setShowErrorModal(true);
            setLoading(false)
            return;
        } else {
            setShowModal(true);
            setLoading(false)
            setEmail('');
        }

    }

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*body*/}
                                <div className="relative p-6 pb-0 flex-auto">
                                    <p className="mt-4 text-slate-500 text-lg leading-relaxed">
                                        Congratulations!! You are one of the lucky winners to win exciting offers on Ora. Please have some patience and wait for us to launch.
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showErrorModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*body*/}
                                <div className="relative p-6 pb-0 flex-auto">
                                    <p className="mt-4 text-slate-500 text-lg leading-relaxed">
                                        Some error occurred. Please check the details or try again.
                                    </p>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowErrorModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div id="subscribe" className="relative">
                <section className="text-white newsletter-bg body-font">
                    <div className="container mx-auto flex flex-col px-5 pt-10 justify-center items-center">
                        <div className="w-full md:w-2/3 flex flex-col mb-1 items-center text-center">
                            <h1 className="title-font sm:text-4xl text-3xl mb-4 text-white font-medium">Stay in the loop</h1>
                            <p className="mb-8 leading-relaxed">Join our newsletter to receive top news before anyone
                                else</p>
                            <div className={`flex w-full justify-center items-end`}>
                                <div className="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
                                    <input placeholder="Enter your email..." type="email" id="hero-field" name="hero-field" value={email} onChange={(event) => setEmail(event.target.value)} required
                                        className="w-full bg-gray-100 bg-opacity-50 focus:ring-2 focus:ring-indigo-200 focus:bg-white border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 placeholder:text-gray-700 transition-colors duration-200 ease-in-out" />
                                </div>
                                <button onClick={onHandleSubmitClick} className="inline-flex text-purple-600 bg-white border-0 py-2 px-6 focus:outline-none hover:bg-gray-100 text-lg">Subscribe</button>
                            </div>
                        </div>
                    </div>
                </section>
                <img className="w-screen" src={vector} alt="" />
                {loading && (
                    <div className='newsletter-loader'>
                        <Loader />
                    </div>)}

            </div>
        </>
    )
};

export default NewsLetter;