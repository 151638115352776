import React from "react";
import "./Loader.css";

const Loader = () => {
	return (
		<div class='load-wrapper'>
			<div class='load-circle'></div>
			<div class='load-circle'></div>
			<div class='load-circle'></div>
			<div class='load-shadow'></div>
			<div class='load-shadow'></div>
			<div class='load-shadow'></div>
		</div>
	);
};

export default Loader;
