import React, { useState } from 'react';
import './styles.css';

function VideoComponent() {
  const [playing, setPlaying] = useState(false);

  const handleMouseEnter = () => {
    setPlaying(true);
  };

  const handleMouseLeave = () => {
    setPlaying(false);
  };

  const videoId = 'dbgK73GH1Uo'

  return (
    <div id="aboutus" className="video-component">
      <h2 className="heading">Event Large Or Small Rely On Ora</h2>
      <div className="video-wrapper" data-aos="fade-down">
        <div className="video-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <iframe
            title='Ora-Introduction'
            src={`https://www.youtube.com/embed/${playing ? `${videoId}?autoplay=1` : videoId}`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="text-container">
          <p className='description'>
            Say goodbye to stressful event planning and hello to Ora's hassle-free event hosting and ticketing platform, designed to make your event planning experience easy and customizable.
          </p>
        </div>
      </div>
    </div>
  );
}


export default VideoComponent;
