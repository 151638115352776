import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "../../../utils/AxiosInstance";

export const pricingData = createAsyncThunk(
   "pricingdata",
   async (_, { rejectWithValue }) => {
      const URL = "/subscription/models"
      try {
         let res = await AxiosInstance(URL)
         console.log(res);
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         rejectWithValue(error)
      }
   }
)