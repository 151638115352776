import React from "react";
import "./style.css";
import Navbar from "../../../Components/Navbar/Navbar";
import Content from "./Content";

import PageTitle from "../../../Components/PageTitle/pagetitle";

const FILE_NAME = "ReturnAndRefund.jsx";
const TITLE = "Return & Refund";

const ReturnAndRefundPolicy = () => {
	console.log(FILE_NAME);
	return (
		<>
			<PageTitle title={TITLE} />
			<div>
				<div className='rrp-nav'>
					<Navbar />
				</div>
				<div className='rrp-content'>
					<Content />
				</div>
			</div>
		</>
	);
};

export default ReturnAndRefundPolicy;
