import CreateManage from './Assets/createmanageevents.png';
import CustomizeEvents from './Assets/customizeevents.png';
import MultipleHostingOptions from './Assets/multiplehostingoptions.png';
import AttendeeTracking from './Assets/attendeetracking.png';
import EventTicketing from './Assets/eventticketing.png';
import EventAnalytics from './Assets/eventanalytics.png';

export const FeaturesData = [
    {
        icon: CreateManage,
        heading: 'Create & Manage events',
        description: " Just create your event and sit back, we will handle the hassle of management."
    },

    {
        icon: CustomizeEvents,
        heading: 'Customize events',
        description: "We match your vibe by providing 50+ theme customizations that enable you to  personalize your event to your unique vision and style."
    },

    {
        icon: MultipleHostingOptions,
        heading: 'Multiple hosting options',
        description: "Doesn't matter if it's your birthday party or office trip, we got you covered with multiple domains in which you can host an event."
    },

    {
        icon: AttendeeTracking,
        heading: 'Marketing Tools',
        description: "Ora's marketing tools enable effective event promotion through various channels, expanding reach and increasing attendance for successful events."
    },

    {
        icon: EventTicketing,
        heading: 'QR Ticketing',
        description: "Ora's QR ticketing provides secure and efficient event check-ins with scannable QR codes, enhancing both the attendee experience and event security."
    },

    {
        icon: EventAnalytics,
        heading: 'Event analytics',
        description: "Ora's event analysis feature provides valuable insights into attendee engagement, enabling event organizers to optimize their strategy and maximize ROI."
    },
];